import firebase from "firebase/app";
import { TaskSummary } from "@/entities/submission_master";

export async function postFile(
  file: File,
  options: {
    path: string;
  }
): Promise<{ fileRef: firebase.storage.Reference; downloadURL: string }> {
  const storageRef = firebase.storage().ref();
  const fileRef = storageRef.child(options.path);

  const res = await fileRef.put(file);
  const downloadURL = (await res.ref.getDownloadURL()) as string;
  return {
    fileRef,
    downloadURL
  };
}

export async function deleteFile(fileRef: firebase.storage.Reference) {
  const res = await fileRef.delete();
  return res;
}

export async function postFileAsUploadTask(
  file: File,
  options: {
    path: string;
    onStateChanged?: (taskSummary: TaskSummary) => any;
    onError?: () => any;
    onComplete?: (ref: firebase.storage.Reference) => any;
  }
): Promise<firebase.storage.UploadTask> {
  const storageRef = firebase.storage().ref();
  const fileRef = storageRef.child(options.path);
  const task = fileRef.put(file);
  // https://firebase.google.com/docs/reference/node/firebase.storage.UploadTask#on
  task.on(
    firebase.storage.TaskEvent.STATE_CHANGED,
    snapshot => {
      options.onStateChanged &&
        options.onStateChanged({
          bytesTransferred: snapshot.bytesTransferred,
          totalBytes: snapshot.totalBytes,
          transferredPercentage: Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
        });
    },
    options.onError,
    () => options.onComplete && options.onComplete(fileRef)
  );
  return task;
}

export async function uploadImage(
  file: File,
  studentId: string
): Promise<string> {
  const storageRef = firebase.storage().ref();

  const imageRef = storageRef.child(
    `images/students/${studentId}/messages/${Date.now()}${Math.floor(
      Math.random() * 10000
    )}`
  );

  const res = await imageRef.put(file);
  const url = await res.ref.getDownloadURL();
  return url as string;
}
